/* ************************************
    GENERAL LAYOUT
 ************************************ */
body {
    font-family: 'Arial', sans-serif;
    background-image: url('../images/bg-rezept.png');

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    opacity: 0.90;
}

a {
    text-decoration: none;
    color: inherit;
}


/* Navigation Menu Items */
.menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.menu-item span {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #ccc;
}

/* Main Container (Default-View) */
.main-content {
    margin: 60px 0;
    padding: 0;
}

@media (min-width: 600px) {
    .main-content {
        margin: 70px 0;
    }
}

@media (min-width: 900px) {
    .main-content {
        margin-top: 85px;
    }
}


/* ************************************
    Seiten-Spezifisch
 ************************************ */


/* Startseite */

img.startseite {
    height: 100px;
    width: 100px;
}

img.startseite.left {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

img.startseite.right {
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
}

/* Rezept Suche */

.rezept-suche-card {
    border: 1px solid #004400;
    background-color: #E4FDE4 !important;

}

/* Rezept Edit */

.kochschritt-form-box {
    /*background-color: #669999;*/
    background-color: #B8F5BA;
    border: 3px solid #004400;
    border-radius: 5px;

    padding: 5px;
    margin: 20px 0;
}

.file-picker {
    text-align: center;
    background-color: #B8F5BA;
    padding: 5px;
    border: 2px dotted #004400;
    border-radius: 5px;
}

.file-picker .drag-drop-area {
    background-color: #E2FBFB;
    height: 200px;
    padding: 10px;
    border: 1px solid #004400;
    border-radius: 5px;
}


/* Nährwerte */

.nutrient-score-good {
    background-color: green !important;
    color: white !important;
}

.nutrient-score-bad {
    background-color: darkred !important;
    color: white !important;
}

/* Label */

.tag-label {
    background-color: #B8F5BA;
    color: #004400;
    padding: 0 2px;
    border: 3px solid #B8F5BA;
    border-radius: 5px;
    margin-right: 5px;
}

.tag-label.red {
    border-color: orange;
    background-color: orange;
    color: darkred;
}
.tag-label.green {
    border-color: green;
    background-color: green;
    color: white;
}
.tag-label.lightblue {
    border-color: lightblue;
    background-color: lightblue;
    color: darkblue;
}


/* Formatter */
.align-vertically {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

.viewer-box {
    background-color: #E2FBFB;
    padding: 5px;
    border: 1px dotted #004400;
    border-radius: 5px;
}

/* Form Layout */
.form-group {
    border: 1px dotted #004400;
    border-radius: 5px;
    padding: 8px 5px;
    margin-bottom: 15px;
    background-color: #E2FBFB;
}


